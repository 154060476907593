@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  word-break: keep-all !important;
}

.break-all {
  word-break: break-all !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999 !important;
}

.no-control video::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

.no-control video::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

.no-control video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

table {
  width: 100%;
  border: 1px solid #444444;
  border-collapse: collapse;
  font-size: 16px;
}

tr {
  height: 46px;
}

th {
  background-color: #eeeeee;
  border: 1px solid #444444;
  text-align: center;
}

td {
  border: 1px solid #444444;
  padding: 5px 8px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

input[value='0000-00-00']::-webkit-datetime-edit {
  color: transparent !important;
}
/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.no-round {
  -webkit-appearance: none;
  border-radius: 0;
}

* {
  font-family: 'Pretendard' !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
/* .outer::-webkit-scrollbar {
  display: none;
} */

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #333333;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff6d51;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fe9985;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}
.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.react-horizontal-scrolling-menu--inner-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
.react-horizontal-scrolling-menu--scroll-container {
  display: flex;
  max-width: 700px;
  overflow-x: hidden;
}
@media (max-width: 1536px) {
  .react-horizontal-scrolling-menu--scroll-container {
    max-width: 500px;
  }
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 16px;
  width: 16px;
  background-color: white;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
  box-shadow: 0 2px 3px 3px rgb(0, 0, 0, 0.1) !important;
  position: relative;
  z-index: 1;
}

input[type='range']::-moz-range-thumb {
  height: 16px;
  width: 16px;
  background-color: white;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
  box-shadow: 0 2px 3px 3px rgb(0, 0, 0, 0.1) !important;
  position: relative;
  z-index: 1;
}

.inquiry-detail::-webkit-scrollbar {
  display: none !important;
}

.swiper-container {
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
}

.swiper-slide .swiper-animation {
  transform: scale(1);
  opacity: 0;
  transition: all 1.25s ease-in-out;
}

.swiper-slide-active .swiper-animation {
  transform: scale(1);
  opacity: 1;
}

.swiper-slide .swiper-animation-workcenter {
  transition: all 2.25s ease-in-out;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  opacity: 1;
}
.swiper-slide .swing,
.swiper-slide .swing2 {
  opacity: 0;
  transition: all 1.8s ease-in-out;
}

.siper-slide-active .swing,
.siper-slide-active .swing2 {
  opacity: 1;
}
.swiper-slide .swiper-animation-studio {
  transition: all 2.25s ease-in-out;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  opacity: 1;
}
.swiper-slide-active .fade-in-left {
  -webkit-animation-name: fade-in-left;
  animation-name: fade-in-left;
}

.swiper-slide-active .fade-in-left-image {
  -webkit-animation-name: fade-in-left;
  animation-name: fade-in-left;
  animation-delay: 0.6s;
}

.swiper-slide-active .fade-in-left-float {
  animation-name: fade-in-left;
  animation-delay: 1s;
}

.swiper-slide-active .fade-in-left-float img {
  animation: float 4s ease-in-out infinite;
}

.swiper-slide-active .fade-up-image {
  animation-name: fade-up;
  animation-delay: 0.4s;
}

.swiper-slide-active .fade-up-image2 {
  animation-name: fade-up;
  animation-delay: 0.8s;
}

.swiper-slide-active .fade-in-left-float2 {
  animation-name: fade-in-left;
  animation-delay: 1.3s;
}

.swiper-slide-active .fade-in-left-float2 img {
  animation: float 4s ease-in-out infinite 1s;
}

.swiper-slide-active .dim {
  -webkit-animation-name: dim;
  animation-name: dim;
}

.swiper-slide-active .swing {
  animation-delay: 0.6s;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}
.swiper-slide-active .swing2 {
  animation-delay: 0.8s;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}
.swiper-slide-active .swiper-animation-workcenter {
  opacity: 1;
}
.swiper {
  min-height: 106% !important;
}

.swiper-slide-active.plan .gradient-box {
  background: linear-gradient(91.37deg, #ff6d51 -0.11%, #ff9a51 99.89%);
}

.swiper-slide-active.plan .text-box {
  color: #ff6d51 !important;
}

.swiper-slide-active.plan .black-box {
  border: none;
  background-color: #141414 !important;
}

.swiper-slide-active.plan .start-box {
  border: none;
  background-color: #ff6d51;
}

.swiper-pagination {
  transform: translateY(12px) !important;
}

.outlined-text {
  color: #000;
  text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
}

.black-outlined-text {
  color: white;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

.photo-flow {
  animation: flow 30s linear infinite;
}

.aos-animate .history-line {
  animation: lineGrow 2s ease-in-out;
  height: 100%;
}

@keyframes flow {
  from {
    transform: translateX(-200%);
  }
  to {
    transform: translateX(-710%);
  }
}

@keyframes fade-in-left {
  from {
    transform: translateX(60%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-up {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(14px);
  }
}

@keyframes dim {
  from {
    transform: translateX(170%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes lineGrow {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}

.shortcut-banner,
.top-button {
  animation: appear 0.8s ease-in-out;
}

@-webkit-keyframes swing {
  0% {
    opacity: 0;
    -webkit-transform: translateX(1000px);
  }
  10% {
    opacity: 1;
    -webkit-transform: translateX(0) rotate(13deg);
  }
  20% {
    -webkit-transform: rotate(-12deg);
  }
  30% {
    -webkit-transform: rotate(8deg);
  }
  40% {
    -webkit-transform: rotate(-5deg);
  }
  50% {
    -webkit-transform: rotate(3deg);
  }
  60% {
    -webkit-transform: rotate(-2deg);
  }
  70% {
    -webkit-transform: rotate(2deg);
  }
  80% {
    -webkit-transform: rotate(-1deg);
  }
  90% {
    -webkit-transform: rotate(1deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes swing {
  0% {
    opacity: 0;
    transform: translateX(1000px);
  }
  10% {
    opacity: 1;
    transform: translateX(0) rotate(13deg);
  }
  20% {
    transform: rotate(-12deg);
  }
  30% {
    transform: rotate(8deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(3deg);
  }
  60% {
    transform: rotate(-2deg);
  }
  70% {
    transform: rotate(2deg);
  }
  80% {
    transform: rotate(-1deg);
  }
  90% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

footer {
}

.terms-container div > ol > li > ol > li > h3 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 30px 0;
}

.terms-container div > ol > li > ol > li > ol {
  line-height: 2.5rem;
  font-size: 1.25rem;
}
.terms-container li {
  margin: 10px 0;
}
.terms-container {
  font-size: 1.2rem;
}
.terms-container em {
  font-style: normal;
}

.terms-box div > ol > li > ol > li > h3 {
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.5rem;
  margin: 20px 0;
}

.terms-box div > ol > li > ol > li > ol {
  line-height: 1.5rem;
}
.terms-box li {
  margin: 10px 0;
}
.terms-box {
  font-size: 1.05rem;
}
.terms-box em {
  font-style: normal;
}

@keyframes highlight {
  10% {
    opacity: 0.4;
  }
  15% {
    opacity: 0.8;
  }
  20% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  35% {
    opacity: 0.8;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.4;
  }
}

.metaProList-0 {
  color: white;
  opacity: 0.4;
  animation: highlight 10s linear infinite;
}
.metaProList-1 {
  color: white;
  opacity: 0.4;
  animation: highlight 10s linear infinite;
  animation-delay: 2s;
}
.metaProList-2 {
  color: white;
  opacity: 0.4;
  animation: highlight 10s linear infinite;
  animation-delay: 4s;
}
.metaProList-3 {
  color: white;
  opacity: 0.4;
  animation: highlight 10s linear infinite;
  animation-delay: 6s;
}
.metaProList-4 {
  color: white;
  opacity: 0.4;
  animation: highlight 10s linear infinite;
  animation-delay: 8s;
}

@keyframes copy-animation {
  10% {
    opacity: 0;
  }
  15% {
    opacity: 0.2;
  }
  20% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
  }
  35% {
    opacity: 0.8;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: 0.8;
  }
  80% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes copy-animation-last {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  15% {
    opacity: 0.2;
  }
  20% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
  }
  35% {
    opacity: 0.8;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.homePhrase-0 {
  color: white;
  opacity: 0;
  animation: copy-animation 2.5s linear;
}
.homePhrase-1 {
  color: white;
  opacity: 0;
  animation: copy-animation 4s linear;
  animation-delay: 2.5s;
}
.homePhrase-2 {
  color: white;
  opacity: 0;
  animation: copy-animation 3s linear;
  animation-delay: 6.5s;
}
.homePhrase-3 {
  color: white;
  opacity: 0;
  animation: copy-animation-last 3s linear;
  animation-fill-mode: forwards;
  animation-delay: 9.5s;
}

.signup-policy_modal ol,
.notice-detail ol,
.privacy-policy ol,
.terms-use ol,
.privacy-detail_modal ol {
  margin-left: 18px;
  list-style: decimal;
}

.signup-policy_modal h1,
.notice-detail h1,
.privacy-policy h1,
.terms-use h1,
.privacy-detail_modal h1 {
  font-weight: bold;
}
